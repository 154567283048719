import React, { ReactElement, useEffect, useState } from 'react';
import { ConnectifStore } from '../../../models/ConnectifStore';
import {
    Button,
    Flex,
    Form,
    FormGroup,
    FormProps,
    InlineMessage,
    Input,
    InputProps,
    Modal,
    Select,
    SelectOption,
    Text
} from '@bigcommerce/big-design';
import { useTranslation } from 'react-i18next';
import MultiStoreService from '../../../services/MultiStoreService';
import { useSignedPayload } from '../../../state/SignedPayloadContext';
import ConnectifStoreService from '../../../services/ConnectifStoreService';
import { CircularProgress } from '@mui/material';
import { getErrorMessage } from '../../../utils/errorMessage';
import './StoreForm.css';
import { Channel } from '../../../models/Channel';

interface Props {
    connectifStore: ConnectifStore;
    allowEdit: boolean;
    hasError?: boolean;
    onSave: () => void;
    onRemove: (connectifStoreId: string) => void;
    onCancel: () => void;
}

type StoreFormErrors = {
    clientId: boolean;
    clientSecret: boolean;
    currencyCode: boolean;
    channelId: boolean;
    message?: string;
};

export default function StoreForm({
    connectifStore,
    allowEdit,
    hasError,
    onSave,
    onRemove,
    onCancel
}: Props): ReactElement {
    const { t } = useTranslation();
    const inputErrorMessage = t('STORE.INPUTS.REQUIRED_MESSAGE');
    const generalErrorMessage = t('MESSAGE.GENERAL_ERROR');
    const [isLoading, setIsLoading] = useState<boolean>(true);
    const [storeData, setStoreData] = useState<ConnectifStore>(connectifStore);
    const [channels, setChannels] = useState<Channel[]>([]);
    const [channelsOptions, setChannelsOptions] = useState<
        SelectOption<string>[]
    >([]);
    const [storeCurrenciesOptions, setStoreCurrenciesOptions] = useState<
        SelectOption<string>[]
    >([]);
    const { signedPayload } = useSignedPayload();

    const initErrors: StoreFormErrors = {
        clientId: false,
        clientSecret: false,
        currencyCode: false,
        channelId: false
    };
    const [errors, setErrors] = useState<StoreFormErrors>(initErrors);
    const [isModalOpen, setIsModalOpen] = useState<boolean>(false);

    useEffect(() => {
        setIsLoading(true);
        loadChannels();
        setStoreData(connectifStore);
        setIsLoading(false);
    }, [connectifStore]);

    useEffect(() => {
        setIsLoading(true);
        if (connectifStore.channelId && channels) {
            const channel = channels.find(
                channel => channel.id.toString() === connectifStore.channelId
            );
            if (channel) {
                loadStoreCurrenciesByChannelCurrencies(
                    channel.currencies.enabled_currencies
                );
            }
        }
        setIsLoading(false);
    }, [channels]);

    const loadChannels = async () => {
        const channels = await ConnectifStoreService.getChannels(signedPayload);
        if (channels) {
            const channelsOptions = channels.map(channel => ({
                value: channel.id.toString(),
                content: channel.name
            }));
            setChannelsOptions(channelsOptions);
            setChannels(channels);
        } else {
            setErrors(prevErrors => ({
                ...prevErrors,
                message: t('MESSAGE.CANNOT_LOAD_CURRENCIES')
            }));
        }
    };

    const loadStoreCurrenciesByChannelCurrencies = async (
        channelCurrencies: string[]
    ) => {
        const storeCurrencies =
            await ConnectifStoreService.getStoreCurrencies(signedPayload);
        if (storeCurrencies) {
            const storeCurrenciesOptions = storeCurrencies
                .filter(currency =>
                    channelCurrencies.includes(currency.currency_code)
                )
                .map(storeCurrency => ({
                    value: storeCurrency.currency_code,
                    content: storeCurrency.name
                }));
            setStoreCurrenciesOptions(storeCurrenciesOptions);
        } else {
            setErrors(prevErrors => ({
                ...prevErrors,
                message: t('MESSAGE.CANNOT_LOAD_CURRENCIES')
            }));
        }
    };

    const handleOnChangeClientId: InputProps['onChange'] = event => {
        setStoreData(prevData => ({
            ...prevData,
            clientId: event.target.value
        }));
        setErrors(prevErrors => ({ ...prevErrors, clientId: false }));
    };

    const handleOnChangeClientSecret: InputProps['onChange'] = event => {
        setStoreData(prevData => ({
            ...prevData,
            clientSecret: event.target.value
        }));
        setErrors(prevErrors => ({ ...prevErrors, clientSecret: false }));
    };

    const handleOnChangeChannel = async (value: string) => {
        setStoreData(prevData => ({
            ...prevData,
            channelId: value
        }));
        setErrors(prevErrors => ({ ...prevErrors, channelId: false }));
        const channel = channels.find(channel => channel.id === +value);
        if (channel?.currencies.enabled_currencies) {
            await loadStoreCurrenciesByChannelCurrencies(
                channel?.currencies.enabled_currencies
            );
        }
    };

    const handleOnChangeCurrency = (value: string) => {
        setStoreData(prevData => ({
            ...prevData,
            currencyCode: value
        }));
        setErrors(prevErrors => ({ ...prevErrors, currencyCode: false }));
    };

    const handleConfirmDeleteStore = async () => {
        setIsModalOpen(false);
        setIsLoading(true);
        try {
            if (!connectifStore.id) {
                setErrors(prevErrors => ({
                    ...prevErrors,
                    message: generalErrorMessage
                }));
                return;
            }
            const deleteResult = await MultiStoreService.deleteStore(
                signedPayload,
                connectifStore.id
            );

            if (!deleteResult.success) {
                const message = getErrorMessage(deleteResult.errorCode, t);
                setErrors(prevErrors => ({
                    ...prevErrors,
                    message
                }));
                return;
            }
            onRemove(connectifStore.id);
        } catch (error) {
            setErrors(prevErrors => ({
                ...prevErrors,
                message: generalErrorMessage
            }));
        } finally {
            setIsModalOpen(false);
            setTimeout(() => {
                setIsLoading(false);
            }, 5000);
        }
    };

    const handleCancelAddStore = async () => {
        if (connectifStore.id) {
            const deleteResult = await MultiStoreService.deleteStore(
                signedPayload,
                connectifStore.id
            );

            if (!deleteResult) {
                setErrors(prevErrors => ({
                    ...prevErrors,
                    message: t('MESSAGE.CANNOT_REMOVE_STORE')
                }));
                return;
            }
        }
        onCancel();
    };

    const validateForm = () => {
        const formErrors: StoreFormErrors = {
            clientId: !storeData.clientId?.trim(),
            clientSecret: !storeData.clientSecret?.trim(),
            channelId: !storeData.channelId?.trim(),
            currencyCode: !storeData.currencyCode?.trim()
        };

        setErrors(formErrors);

        return (
            !formErrors.clientId &&
            !formErrors.clientSecret &&
            !formErrors.channelId &&
            !formErrors.currencyCode
        );
    };

    const handleSubmit: FormProps['onSubmit'] = async event => {
        event.preventDefault();
        event.stopPropagation();

        if (!validateForm()) {
            return;
        }

        setIsLoading(true);
        try {
            if (storeData.id) {
                await MultiStoreService.deleteStore(
                    signedPayload,
                    storeData.id
                );
            }

            const result = await MultiStoreService.addStore(signedPayload, {
                clientId: storeData.clientId,
                clientSecret: storeData.clientSecret,
                currencyCode: storeData.currencyCode,
                channelId: storeData.channelId
            });

            if (!result.success) {
                setErrors({
                    ...errors,
                    message: getErrorMessage(result.errorCode, t)
                });
            } else {
                onSave();
            }
        } catch (error) {
            setErrors(prev => ({ ...prev, message: generalErrorMessage }));
        } finally {
            setTimeout(() => {
                setIsLoading(false);
            }, 1000);
        }
    };

    return (
        <React.Fragment>
            {isLoading && (
                <Flex
                    className='cn-background-sppiner'
                    justifyContent={'center'}
                    alignItems={'center'}
                >
                    <CircularProgress size={40} />
                </Flex>
            )}

            {connectifStore.disabled && (
                <InlineMessage
                    marginVertical='medium'
                    messages={[
                        {
                            text: t('STORE.DISABLED_MESSAGE')
                        }
                    ]}
                    type='warning'
                />
            )}

            <Form noValidate onSubmit={handleSubmit}>
                <FormGroup>
                    <Input
                        label={t('STORE.INPUTS.CLIENT_ID')}
                        type='text'
                        onChange={handleOnChangeClientId}
                        required
                        minLength={1}
                        value={storeData.clientId}
                        disabled={!allowEdit}
                        error={errors.clientId && inputErrorMessage}
                    />
                </FormGroup>
                <FormGroup>
                    <Input
                        label={t('STORE.INPUTS.CLIENT_SECRET')}
                        type='text'
                        onChange={handleOnChangeClientSecret}
                        required
                        minLength={1}
                        value={storeData.clientSecret}
                        disabled={!allowEdit}
                        error={errors.clientSecret && inputErrorMessage}
                    />
                </FormGroup>
                <FormGroup>
                    <Select
                        filterable={true}
                        label={t('STORE.MULTISTORE.SELECT_CHANNEL.LABEL')}
                        maxHeight={300}
                        onOptionChange={handleOnChangeChannel}
                        options={channelsOptions}
                        placeholder={t(
                            'STORE.MULTISTORE.SELECT_CHANNEL.PLACEHOLDER'
                        )}
                        value={storeData.channelId}
                        required
                        disabled={!allowEdit}
                        error={errors.channelId && inputErrorMessage}
                    />
                </FormGroup>
                <FormGroup>
                    <Select
                        filterable={true}
                        label={t('STORE.MULTISTORE.SELECT_CURRENCY.LABEL')}
                        maxHeight={300}
                        onOptionChange={handleOnChangeCurrency}
                        options={storeCurrenciesOptions}
                        placeholder={t(
                            'STORE.MULTISTORE.SELECT_CURRENCY.PLACEHOLDER'
                        )}
                        value={storeData.currencyCode}
                        required
                        disabled={
                            !allowEdit || storeCurrenciesOptions.length === 0
                        }
                        error={errors.currencyCode && inputErrorMessage}
                    />
                </FormGroup>
                <FormGroup>
                    <div>
                        {!allowEdit && connectifStore.id && (
                            <Button
                                type='button'
                                variant='secondary'
                                actionType='destructive'
                                onClick={() => setIsModalOpen(true)}
                            >
                                {t('STORE.MULTISTORE.DELETE_STORE')}
                            </Button>
                        )}

                        {allowEdit && (
                            <>
                                <Button type='submit' variant='secondary'>
                                    {t('STORE.MULTISTORE.SAVE_STORE')}
                                </Button>
                                <Button
                                    type='button'
                                    variant='secondary'
                                    actionType='destructive'
                                    onClick={handleCancelAddStore}
                                >
                                    {t('STORE.MULTISTORE.CANCEL')}
                                </Button>
                            </>
                        )}
                    </div>
                </FormGroup>
                {(errors.message || hasError) && (
                    <InlineMessage
                        marginVertical='medium'
                        messages={[
                            {
                                text: errors.message
                                    ? errors.message
                                    : t('STORE.ERROR_MESSAGE')
                            }
                        ]}
                        type='error'
                    />
                )}
            </Form>

            <Modal
                actions={[
                    {
                        text: t('STORE.MULTISTORE.CONFIRM_MODAL.CANCEL'),
                        variant: 'subtle',
                        onClick: () => setIsModalOpen(false)
                    },
                    {
                        text: t('STORE.MULTISTORE.CONFIRM_MODAL.CONFIRM'),
                        onClick: () => handleConfirmDeleteStore()
                    }
                ]}
                closeOnClickOutside={false}
                closeOnEscKey={true}
                header={`${t('STORE.MULTISTORE.CONFIRM_MODAL.HEADER')} ${connectifStore.clientId}?`}
                isOpen={isModalOpen}
                onClose={() => setIsModalOpen(false)}
            >
                <Text>{t('STORE.MULTISTORE.CONFIRM_MODAL.DESCRIPTION')}</Text>
            </Modal>
        </React.Fragment>
    );
}
